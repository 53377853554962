<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col class="grow">
        <v-text-field
          v-model="search"
          id="search-bar"
          :label="searchLabel"
          single-line
          hide-details
          clearable
          @keyup.enter="onSearch"
          @click:clear="onSearch"
          dense
          outlined
          background-color="white"
          light
          color="msaBlue"
          class="search-bar"
          :disabled="disabled"
          data-testid="search-bar"
        />
      </v-col>
      <v-col class="shrink">
        <v-btn
          height="40"
          class="msaBlue white--text search-bar-btn"
          @click="onSearch"
          :style="{
            background: 'linear-gradient(to bottom,  #0a5fb1 0%,#093f79 100%)',
          }"
          elevation="0"
          :disabled="disabled"
          data-test-id="search-button"
          data-testid="search-button"
        >
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'SearchBar',
  props: {
    searchLabel: {
      type: String,
      default: 'Search',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value ?? '');
      },
    },
  },
  methods: {
    async onSearch() {
      // this makes sure the clear event is fired before the on search emit
      await this.$nextTick();
      this.$emit('search');
    },
  },
};
</script>
<style>
.search-bar {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.search-bar-btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
</style>
