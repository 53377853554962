<template>
  <v-container class="pa-0 mx-0" fluid>
    <v-dialog v-model="dialog" max-width="500" :persistent="persistent">
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              v-blur
              :color="isFilterApplied ? 'orange' : 'msaBlue'"
              class="white--text"
              @click="resetFields"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          {{ tooltip }}
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title class="headline msaBlue white--text">
          <v-row>
            <v-col>{{ title }}</v-col>
            <v-col class="shrink">
              <v-btn @click="dialog = false" dark icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row
            v-for="(field, index) in fields"
            :key="index"
            class="my-3"
            no-gutters
          >
            <v-col class="font-weight-bold" cols="12">
              {{ field.title }}
            </v-col>

            <v-row v-if="field.type == 'date-range'" no-gutters>
              <v-col class="mr-2">
                <v-menu
                  v-model="menus[field.key.from]"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-model="selectedFilters[field.key.from]"
                      v-on="on"
                      :label="field?.placeholder?.from || 'From'"
                      append-icon="mdi-calendar"
                      clearable
                      dense
                      hide-details
                      outlined
                      readonly
                    />
                  </template>
                  <v-date-picker
                    v-model="selectedFilters[field.key.from]"
                    @input="menus[field.key.from] = false"
                    :min="$constants.DATE_SELECTOR_RANGE.MIN"
                    :max="
                      selectedFilters[field.key.to] ||
                      $constants.DATE_SELECTOR_RANGE.MAX
                    "
                    no-title
                  />
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menus[field.key.to]"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-model="selectedFilters[field.key.to]"
                      v-on="on"
                      :label="field?.placeholder?.to || 'To'"
                      append-icon="mdi-calendar"
                      clearable
                      dense
                      hide-details
                      outlined
                      readonly
                    />
                  </template>
                  <v-date-picker
                    @input="menus[field.key.to] = false"
                    v-model="selectedFilters[field.key.to]"
                    :min="
                      selectedFilters[field.key.from] ||
                      $constants.DATE_SELECTOR_RANGE.MIN
                    "
                    :max="$constants.DATE_SELECTOR_RANGE.MAX"
                    no-title
                  />
                </v-menu>
              </v-col>
            </v-row>

            <v-col v-else-if="field.type == 'multi-select'">
              <v-btn-toggle
                :value="selectedFilters[field.key]"
                @change="selectedFilters[field.key] = $event.toSorted()"
                color="msaBlue"
                dense
                mandatory
                multiple
                style="width: 100%"
              >
                <v-btn
                  v-for="(option, index) in field.options"
                  :key="index"
                  :value="option.value"
                  class="text-capitalize"
                  style="width: 33%"
                >
                  {{ option.title }}
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col v-else-if="field.type == 'text-field'">
              <v-text-field
                v-model.trim="selectedFilters[field.key]"
                :placeholder="field.placeholder"
                clearable
                dense
                hide-details
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="clear()" color="red" text>clear</v-btn>
          <v-btn
            :disabled="!hasChanges"
            class="msaBlue white--text"
            @click="apply()"
          >
            apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/**
 * Welcome to the StandardFilter - 1Life's custom filter for use across Client Health Tool.
 * Refer to the documentation below to see the usage of this component's properties.
 *
 * This is the filter that appears as a button, but expands to a complete
 * dialog for filter customization. The goal of this filter was to standardize
 * the appearance and UX of the filter component in CHT.
 *
 * The filter takes in a configuration (this.fields), and upon hitting
 * 'save' or 'clear', emits a filter object.
 *
 * @emits apply upon hitting 'save' or 'clear'
 *
 * NOTE - This filter was originally designed along with the
 * CorrectiveActionsReportPage and SyncLogsPage
 *
 * Example:
 * <StandardFilter
 *   :default="$options.defaultFilters"
 *   :fields="$options.filterFields"
 *   :filters="selectedFilters"
 *   @apply="applyFilter($event)"
 * />
 */
export default {
  name: 'StandardFilter',
  props: {
    /**
     * Additional data that must be provided to populate certain fields.
     * (This hasn't been implemented yet)
     *
     * Currently only applies for autocomplete field types, which is often
     * used for user selections.
     *
     * This is an Array of Objects with the following properties:
     *
     * Usage:
     *  - key (string):
     *    The key of the field that this data will map to
     *  - data (array):
     *    Reference to the data that the field will read from.
     *  - itemText, itemValue (string, optional):
     *    Settings for the CustomizedAutoComplete's :item-text
     *    and :item-value properties
     *
     * Example:
     * [{ key: 'assignedTo',
     *    data: users,
     *    itemText: 'fullname',
     *    itemValue: 'ID',
     * }]
     */
    data: {
      type: Array,
    },
    /**
     * The default state of the filters. This is used to determine if any
     * changes have been made in the filter dialog, and is required if your
     * filter has any fields that aren't plain blank text fields.
     */
    default: {
      type: Object,
    },
    /**
     * Declarative configuration for the filter.
     *
     * This is a list of objects for configuring the filter's fields.
     *
     * Common object properties:
     *  - key (string, required): The property name that the field will map to
     *  - type (string, required): The type of field that the object is declaring
     *  - title (string, optional): The label that the filter field will have
     *  - placeholder (string, optional): The placeholder text of the field
     *
     * Object format for each field type:
     *
     * Autocompletes: (to be implemented)
     * An autocomplete must be accompanied by a matching element in the this filter's data property.
     * {
     *   type: 'autocomplete',
     *   title: 'Users',
     *   key: 'assignedTo',
     *   placeholder: 'Select user(s)',
     * },
     *
     * Date ranges:
     * The date-range's key must be an Object with 'from' and 'to' keys
     * {
     *   type: 'date-range',
     *   title: 'Created Date',
     *   key: { from: 'createdFrom', to: 'createdTo' },
     *   placeholder: { from: 'Created From', to: 'Created To'} // Defaults are 'From', and 'To'
     * }
     *
     * Multi-selects:
     * Appears as multiple options to choose from.
     * The options property (required) defines the different options to choose
     * from, as well as the values they'll map to.
     * {
     *   type: 'multi-select',
     *   title: 'Form Status',
     *   key: 'formStatus',
     *   options: [
     *     {
     *       title: 'Submitted',
     *       value: this.$constants.FORM_INSTANCE_STATUS.SUBMITTED,
     *     },
     *     {
     *       title: 'Reviewed',
     *       value: this.$constants.FORM_INSTANCE_STATUS.REVIEWED,
     *     },
     *     {
     *       title: 'Finalized',
     *       value: this.$constants.FORM_INSTANCE_STATUS.FINALIZED,
     *     },
     *   ],
     * }
     *
     * Text Fields:
     * A simple one-line text-field for entering a string.
     * {
     *   type: 'text-field',
     *   title: 'Form Title',
     *   key: 'formLabel',
     *   placeholder: 'Enter a form title...',
     * }
     *
     * Toggles: (to be implemented)
     * For making a single selection from multiple choices
     * (like a radio button, but uglier).
     * {
     *   type: 'toggle',
     *   title: 'User Status',
     *   key: 'userStatus',
     *   options: [
     *     { title: 'All', value: 2 },
     *     { title: 'Active', value: 1 },
     *     { title: 'Inactive', value: 0 },
     *   ],
     * }
     *
     * Example filter fields:
     * const defaultFields = [
     *   {
     *     type: 'text-field',
     *     key: 'name',
     *     title: 'First Name'
     *   },
     *   {
     *     type: 'toggle',
     *     key: 'status',
     *     title: 'User Status',
     *     options: [
     *       { title: 'Active', value: 1 },
     *       { title: 'Inactive', value: 0 },
     *     ],
     *   },
     * ];
     */
    fields: {
      type: Array,
      required: true,
    },
    /**
     * The values with which this filter component will populate its fields.
     * This property is how you apply initial values for your filter.
     * You will typically pass in the page's reactive filter data
     * Example format:
     * {
     *   name: 'Cats',
     *   dateFrom: '1970-01-01',
     * };
     */
    filters: {
      type: Object,
      required: true,
    },
    persistent: Boolean,
    title: {
      type: String,
      default: 'Filters',
    },
    tooltip: {
      type: String,
      default: 'Filter',
    },
  },
  data() {
    return {
      defaultFilters: {},
      dialog: false,
      menus: {},
      selectedFilters: {},
    };
  },
  computed: {
    hasChanges() {
      return (
        JSON.stringify(this.selectedFilters) !== JSON.stringify(this.filters)
      );
    },
    isFilterApplied() {
      return JSON.stringify(this.filters) !== JSON.stringify(this.default);
    },
  },
  mounted() {
    if (this.default) {
      this.defaultFilters = JSON.parse(JSON.stringify(this.default));
    }
    this.resetFields();
  },
  methods: {
    apply(filters = this.selectedFilters) {
      filters = this.$helpers.toFilterWithoutNulls(filters);
      this.dialog = false;
      this.$emit('apply', filters);
    },
    clear() {
      this.apply(this.defaultFilters);
    },
    /**
     * Resets the fields in the filter to match what is currently applied.
     * This is to clear all non-applied changes for filters that can be closed (non-persistent).
     */
    resetFields() {
      this.selectedFilters = JSON.parse(JSON.stringify(this.filters));
    },
  },
};
</script>
